.button{
    button{
        cursor: pointer;
        min-width: 44px;
        min-height: 44px;
        width: 100%;
        border-radius: 24px;
        padding: 0;
        margin: 0px;
        .text{
            padding: 0;
            width: 100%;
            height: 100%;
            
            p {
                font-weight: bold;
            }

            @media not all and (min-resolution:.001dpcm)
            { @supports (-webkit-appearance:none) and (stroke-color:transparent) {

                p {
                    display: contents;
                    height: .7rem !important;
                    line-height: .5rem;
                }
            }}

            @media not all and (min-resolution:.001dpcm){ 
                @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
                    p {
                        display: contents;
                        height: .7rem !important;
                        line-height: .5rem;
                    }
                }
            }
            @media only screen 
            and (device-width: 390px) 
            and (device-height: 844px) 
            and (-webkit-device-pixel-ratio: 3) { 
                p {
                display: contents;
                height: .7rem !important;
                line-height: .5rem;
                }
            }
            @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
                p {
                display: contents;
                height: .7rem !important;
                line-height: .5rem;
                }
            }
            @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
                p {
                display: contents;
                height: .7rem !important;
                line-height: .5rem;
                }
            }

            @supports (-webkit-touch-callout: none) {
                p {
                    display: contents;
                    height: .7rem !important;
                    line-height: .5rem;
                }
            }
        }
    }

    button:disabled{
        opacity: .5;
    }
}