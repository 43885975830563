

.buttonBlocker{
  width: 100vw;
  height: 100svh;
  z-index: 10;
  position: absolute;
  top: 0;
  @media not all and (min-resolution:.001dpcm)
  { 
      @supports (-webkit-appearance:none) and (stroke-color:transparent) {
          height: -webkit-fill-available;
      }
  }
  @media not all and (min-resolution:.001dpcm){ 
    @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
      height: -webkit-fill-available;
    }
  }
  @media only screen 
          and (device-width: 390px) 
          and (device-height: 844px) 
          and (-webkit-device-pixel-ratio: 3) { 
            height: -webkit-fill-available;
          }
          @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
            height: -webkit-fill-available;
          }
          @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
            height: -webkit-fill-available;
          }
      
          @supports (-webkit-touch-callout: none) {
            height: -webkit-fill-available;
          }
    
}

.zeroSpacing {
  top: 240px; 
  @include media-breakpoint-down(lg) {
    top: 205px; 
  }
  .verdachten-radio{
    position: relative;
    display: flex;    
    justify-content: center;
    flex-direction: column;
  }
  .journal-radio{
    display: none !important;
  }
  .tafel-radio{
    display: none !important;
  }
}

.oneSpacing {
  top: 85px; 

  @include media-breakpoint-down(lg) {
    top: 53px; 
  }
  .verdachten-radio{
    display: none !important;
  }
  .journal-radio{
    display: none !important;
  }
  .tafel-radio{
    left: unset !important;
    position: relative;
    display: flex;    
    justify-content: center;
    flex-direction: column;
  }
}

.twoSpacing {
  
  top: 165px; 
  @include media-breakpoint-down(lg) {
    top: 129px; 
  }

  .verdachten-radio{
    display: none !important;
  }
  .journal-radio{
    position: relative;
    display: flex;    
    justify-content: center;
    flex-direction: column;
  }
  .tafel-radio{
    display: none !important;
  }
}

.workSpace{
  height: 70%;
  max-width: 768px;

  &-header{
    width: 80%;
    margin: 10px 0 0 10%;
    text-align: center;
    h1{
      margin: 0 0 15px 0;
      line-height: 2.3rem;
      font-size: 2.3rem;
    }
    p{
      color: $white;
      font-size: 1.1rem;
    }
  }

  .content {
    width: 100%;
    height: 50%;
    margin-top: 34px;
    justify-content: center;
    display: flex;
  }


  .radioButtons{
    height: 100%;
    width: 100%;
    position: absolute;
    .buttonContainer{
      position: inherit;
      width: 80%;
      height: 190px;
      margin: 0px 10% 0px 10%;
      .radio{
        height: 46px;
        display: flex;
        flex-direction: row;
        margin: auto;
        .icon {
          width: 46px;
          height: 46px;
          display: flex;
          justify-content: center;
          flex-direction: row;
          input{
            margin: 0;
            width: 100%;
            height: 100%;
            -webkit-appearance: none;
            /* Remove most all native input styles */
            appearance: none;
            background-color: #91D2F3;
            opacity: 0.8;
            
            border-radius: 50%;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
          }
          input::before{
            content: "";
            width: 58px;
            height: 58px;
            transition: 120ms transform ease-in-out;
            /* Windows High Contrast Mode */
            transform: scale(0);
            border-radius: 100%;
            border: 2px solid $primary;
            background-color: transparent;
          }
    
          input:checked::before {
            transform: scale(1);
          }
          
          &:hover {
            input::before{
              transform: scale(1);
            }
          }
    
          .dot {
            width: 24px;
            height: 24px;
            background-color: $white;
            border-radius: 50%;
            margin: auto;
            position: absolute;
            top: 10px;
          }
        }
        
        .radioTextBlock{
          display: flex;
          justify-content: center;
          text-align: center;

          .radioText{
            width: fit-content;
            color: $primary;
            text-shadow: 0px 0px 10px $shadow;
            font-size: 1.4rem;

            margin: auto;
            
            &-right {
              margin-left: 5px;
              &--open{
                margin-left: 15px;
              }
            }
            
            &-left {
              margin-right: 5px;
              &--open{
                margin-right: 15px;
              }
            }
          }
        }
      }
  
      .verdachten-radio {
        position: absolute;
      }
      .journal-radio {
        top: 40%;
        right: 0%;
        position: absolute;
      }
      .tafel-radio {
        top: 80%;
        left: 15%;
        position: absolute;
      }

      .description{
        margin-top: 30px;
        color: $white;
        text-align: center;
      }
    }
  }

  
  .swiper{
    top: 82px;
    height: 270px;
    width: 100%;
    max-width: 768px;
    position: absolute;
    overflow: unset;
    z-index: 11;
    // transform: translate(-33.3333333333%,0px);
    
    @media only screen and (max-height: 610px)  {
      top: 50px;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      
      .slide{
        margin: auto;
        width: 300px;
        height: 208px;
        background-color: $white;
        border-radius: 25px;
        display: flex;
        justify-content: center;

        overflow: hidden;
        
        img{
          aspect-ratio: 16 / 9;
          object-fit: cover;
        }

        p{
          color: black;
        }

        @media only screen and (min-width: 600px)  {
          height: 270px;
          width: auto;
        }
        
      }
      .navButton{
        position: absolute;
        left: 50%;
        top: 165%;
        transform: translate(-50%);
        button{
          background-color: #E65100;;
        }

        button:hover{
          background-color: #EE6900;
          box-shadow: none;
        }
        
      }
      
      .close {
        position: absolute;
        left: 50%;
        top: 180%;
        transform: translate(-50%);
      }

      &-next{
        opacity: 0.5;
        .slide{
          height: 170px;
        }
      }

      &-prev{
        opacity: 0.5;
        .slide{
          height: 17s0px;
        }
      }
    }
    
    @media only screen and (min-width: 600px)  {
      .swiper-slide:not(.swiper-slide-active) > div{
        display: none;
      }
    }

    .swiper-slide {
      width: 80%;
    }

    .swiper-button{

      &-prev{
        --arrow: transparent url('data:image/svg+xml,\<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="white"><path d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"/></svg>')  50% 0% no-repeat padding-box;

        width: 44px;
        height: 44px;
        background-color: #556578;
        border-radius: 50%;
        // left: 33.3333333333%;
        &::after{
          background:var(--arrow);  
          fill: $white;
          content: '';
          width: 16px;
          height: 16px;
          transform: rotate(180deg);
        }

        &:hover{
          background-color: #717F8E;
        }
      }

      &-next{
        --arrow: transparent url('data:image/svg+xml,\<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="white"><path d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"/></svg>')  50% 0% no-repeat padding-box;

        width: 44px;
        height: 44px;
        background-color: #556578;
        border-radius: 50%;
        // right: 33.3333333333%;
        &::after{
          background:var(--arrow);  
          fill: $white;
          content: '';
          width: 16px;
          height: 16px;
        }

        &:hover{
          background-color: #717F8E;
        }
      }
    }
  }
  .bottomContainer {
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 100;
    position: relative;
  }
  
.bottomBuffer {
  margin-bottom: 50px;

  @media only screen and (max-height: 500px) {
    margin-bottom: 0px;
  }
}
}
