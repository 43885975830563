
.instructions{
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;

  .right{
    margin-left: auto;
    margin-right: 10px;
  }

    .topContent{
      width: 100%;
      height: 60%;
      display: flex;
      justify-content: center;

      .cover{
        width: 100%;
        height: 100%;
        background: transparent linear-gradient(169deg, #707F8E 0%, #001935 100%) 0% 0% no-repeat padding-box;
        mix-blend-mode: color;

        @media only screen and (min-width: 600px)  {
          border-radius: 25px;
        }
      }
    }
    
    .bottomContent{
      height: 100%;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      z-index: 999;
      .container{
        width: 100%;
        overflow-y: auto;
        margin-left: 22px;

        h2{
          color: $white;
          text-align: left;
          margin-top: 23px;
          margin-bottom: 25px;
          text-shadow: none;
        }

        .bottomItem{
          margin-bottom: 150px !important;
        }

        .explanationMargin{
          margin-bottom: 100px !important;
        }

        .explanationPage{
          min-height: 40px;
          width: 100%;
          margin-bottom: 24px;
          display: flex;
          .noFlex{
            display: block !important;
          }

          .text{
            color: $white;
            width: 285px;
            p{
              font-size: 14px;
              color: $white;
              text-align: left;
              letter-spacing: 0px;
              line-height: 21px;
              margin: auto;
            }

            .blue{
              font-size: 14px;
              color: $primary;
              text-align: left;
              letter-spacing: 0px;
              line-height: 21px;
              font-weight: bold;
              margin: auto;
            }
          }

          .display{
            width: 56px
          }
        }

      }
    }
 
}

