  .notification
  {
    width: 100%;
    background-color: #001935;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 100;
    .content{
      width: 305px;
      height: 200px;

      h3{
        color:$white;
        font-size: 14px;
        margin-bottom: 27.5px;
        text-align: center;
        font-weight: bold;

        @media only screen and (max-height: 450PX)  {
          margin-bottom: 13.75px;
        }
      }

      p{
        color: $white;
        font-size: 14px;
        text-align: center;
      }

      .buttons{
        margin-top: 36px;
        width: 100%;
        display: flex;
        .button{
          margin: auto;
        }

        @media only screen and (max-height: 450PX)  {
          margin-top: 18px;
        }
      }
      .spacing{
        margin-right: 24px !important;
      }
    }
  }

  .menu{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(#001935, 0.95);
    z-index: 999999;
    top: 0;
    
    .menuContainer{
      width: 188px;
      height: 76px;
      margin: auto;
      margin-top: 50vh;
      transform: translate(0,-50%);

      @media only screen and (max-height: 450PX)  {
        height: 30px;
        margin-top: 30vh;
      }

      .divider{
        width: 100%;
        height: 0px;
        border: 0.5px solid $primary;
        opacity: 0.5;
      }

      .top{
        margin-bottom: 14px;
      }

      .bottem{
        margin-top: 12px;
      }

      .item{
        width: 100%;
        height: 25px;
        display: flex;
        .icon{
          width: 18px;
          height: 18px;
          margin: auto;
          fill: #ED6A00;
          g{
            path{
              stroke: #ED6A00;
            }
          }
        }
        .button{
          width: 100%;
          button{
            display: flex;
            height: 100%;
            min-height: unset;
            .text{
              margin: auto;


              @media not all and (min-resolution:.001dpcm)
              { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
                height: .5rem;
                line-height: .5rem;
              }}
              @media not all and (min-resolution:.001dpcm){ 
                @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
                  height: .5rem;
                  line-height: .5rem;
                }
              }

              @media only screen 
              and (device-width: 390px) 
              and (device-height: 844px) 
              and (-webkit-device-pixel-ratio: 3) { 
                height: .5rem;
                line-height: .5rem;
              }
              @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
                height: .5rem;
                line-height: .5rem;
              }
              @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
                height: .5rem;
                line-height: .5rem;
              }
          
              @supports (-webkit-touch-callout: none) {
                height: .5rem;
                line-height: .5rem;
              }
          
              p{
                text-align: left;
                font: normal normal normal 20px/24px Barlow, sans-serif;
                letter-spacing: 0px;
                color: $primary;
                text-shadow: 0px 0px 10px $shadow;
                margin-left: 5px;
              }
            }
          }
        }
        
        
      }
      .clicked {
        .button{
          button{
            .text{
              p{
                color:#ED6A00 !important;
              }
            }
          }
        }

        .icon{
          fill: $primary !important;
          g{
            path{
              stroke: $primary  !important;
            }
          }
        }
      }
    }

    .hideButton{
      display: none !important;
    }

    .popUpSpacing{
      margin-top: 35vh;

      @media only screen and (max-height: 450PX)  {
        margin-top: 24vh;
      }
    }
  }

  .caseInfo {
    width: 222px;
    height: fit-content;
    position: absolute;
    top: 75px;
    left: 50%;
    transform: translate(-75%, 0);
    z-index: 9990100;

    &-right{
      left: unset;
      right: -87px;
    transform: translate(-79.5%, 0);
    }

    .triangle{
      height: 20px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      top: -29px;
      left: 50%;
      transform: translate(-50%,0);
      
      &-red{
        border-bottom: 10px solid red;
      }
      &-yellow{
        border-bottom: 10px solid #E4B905;
      }
      &-gray{
        border-bottom: 10px solid #C6CBD2;
      }
    }

    .divider{
      width: 178px;
      height: 0px;
      border: 0.5px solid #E2E5E8;
      opacity: 0.5;
      margin-left: 22px;
      margin-bottom: 5px;

      @media only screen 
      and (device-width: 390px) 
      and (device-height: 844px) 
      and (-webkit-device-pixel-ratio: 3) { 
        display: none;
      }
      @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
        display: none;
      }
      @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
        display: none;
      }
    
      @supports (-webkit-touch-callout: none) {
        display: none;
      }
    
    }

    .decisionButton{
      margin-left: 22px;
      button{
        min-height: unset;
      }
    }

    .topBorder{
      width: 90%;
      border-radius: 6px  6px 0px 0px;
      top: 0;
      left: 0;
      padding: 5px 5% 5px 5%;
      &-red{
        background-color: red;
      }

      &-yellow{
        background-color: #E4B905;
      }

      &-gray{
        background-color: #C6CBD2;
      }
      
    }

    .contents{
      box-shadow: 0px 0px 10px #32AEC44D;
      border-radius: 8px 7px 7px 8px;
      background-color: $white;
      width: 100%;
      height: fit-content;

      .case-contents{
        height: 75px;
        width: 100%;
        margin-top: 5px;
        display: flex;
        @media only screen 
          and (device-width: 390px) 
          and (device-height: 844px) 
          and (-webkit-device-pixel-ratio: 3) { 
            height: 55px;
            margin: 5px 0px 5px 0px;
          }
          @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
            height: 55px;
            margin: 5px 0px 5px 0px;
          }
          @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
            height: 55px;
            margin: 5px 0px 5px 0px;
          }
        
          @supports (-webkit-touch-callout: none) {
           height: 55px;
           margin: 5px 0px 5px 0px;
          }
        .textArea{
          display: flex;
          margin-left: 5%;
          .bold{
            font-weight: bold;
          }
        }
        .bottom{
          margin-bottom: 4px;
          @media not all and (min-resolution:.001dpcm)
          {
            @supports (-webkit-appearance:none) and (stroke-color:transparent) {
              margin-bottom: unset;
            }
          }

          @media not all and (min-resolution:.001dpcm){ 
            @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
              margin-bottom: unset;
            }
          }
          @media only screen 
              and (device-width: 390px) 
              and (device-height: 844px) 
              and (-webkit-device-pixel-ratio: 3) { 
                margin-bottom: unset;
              }
              @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
                margin-bottom: unset;
              }
              @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
                margin-bottom: unset;
              }
          
              @supports (-webkit-touch-callout: none) {
                margin-bottom: unset;
              }
        }
        .suspect{
          p{
            text-align: left;
            font-size: 14px;
            line-height: 14px;
          }
        }

        p{
            color: black;
        }
  
        .timer {
            position: absolute;
            right: 8%;
            font-size: 24px;
        
        }
      }
    }

    
  }