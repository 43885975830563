.endPage{
    width: 100%;
    height: 100%;
    .container{
        width: auto;
        height: 70%;
        margin-left: 22px;
        margin-right: 22px;

        .title{
            width: 90%;
            text-align: center;
            margin: 30px 5% 0px 5%; 
            h2{
                color: $white;
                text-shadow: unset;
                font-size: 2rem;
            }
        }

        .textContent{
            margin-top: 16px;
            text-align: center;
            p{
                color: $white;
            }
        }
    }
    .bottomContainer {
        justify-content: center;
        button:hover{
            background-color: #F4801B !important;
            box-shadow: none;
          }
      }
}