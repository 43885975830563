

p {
  font-family: Barlow, sans-serif;
  letter-spacing: 0px;
  margin: 0px;
  font-size: 1rem ;
  line-height: 1.5rem;
}


h1, h2, h3, h4 {
  font-weight: normal;
  font-family: Barlow, sans-serif;
  color: $primary;
  margin: 0px;
  width: 100%;
  text-shadow: 0px 0px 10px $shadow;
  word-spacing: 0;
}

h1 {
  font-size: $h1-font-size;
  line-height: px(70);
  color: $primary;
}

h2 {
  font-size:  $h2-font-size;
  line-height: px(55);    
  color: $primary;
}

h3 {
  font-size:  $h3-font-size;
  line-height: px(32);
  color: $primary;

}

h4 {
  font-size:  $h4-font-size;
  line-height: px(40);
  color: $primary;
}