.gameSolved{
    width: 100%;
    height: 100%;
    .title{
        width: 100%;
        text-align: center;
        margin-top: 30px;
        h2{
            color: $white;
            text-shadow: unset;
        }

        @media only screen and (max-width: 450px)  {
          margin-top: 20px;
          margin-bottom: 10px;       
        }
    }
    .container{
        width: auto;
        height: 60%;
        margin-left: 22px;
        margin-right: 22px;
        position: relative;
        overflow: auto;

        .textContent{
            margin-top: 16px;
            text-align: center;
            p{
                color: $white;
            }
        }
        .divider{
            width: 100%;
            height: 0px;
            border: 0.5px solid #E2E5E8;
            opacity: 0.5;
            margin-top: 15px;
          }

        .casesFeedBack
        {
            width: 100%;
            height: fit-content;
            margin-top: 20px;

            .caseInfoMonitor {
                width: auto;
                height: 135px;
                transform: translate(0,0);
                z-index: 10;
                margin: 40px 22px 10px 22px;
                .triangle{
                  height: 20px;
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-bottom: 10px solid $white;
                  position: absolute;
                  top: -29px;
                  left: 50%;
                  transform: translate(-50%,0);
                }
            
                .border{
                  width: 12px;
                  height: 100%;
                  border-radius: 6px  0px 0px 6px;
                  position: fixed;
                  top: 0;
                  left: 0;
                  
                  &-red{
                    background-color: red;
                  }
            
                  &-yellow{
                    background-color: #E4B905;
                  }
            
                  &-gray{
                    background-color: #C6CBD2;
                  }
                }
                .contents{
                  border-radius: 8px 6px 6px 8px;
                  background-color: $white;
                  width: 100%;
                  height: 100%;
                  overflow: hidden;
                  .contentsFlex{
                    display: flex;
                    width: 100%;
                    height: 125px;
                  .case-contents{
                    height: 76px;
                    width: 100%;
                    margin-top: 5px;
                    display: flex;
                    .textArea{
                      display: flex;
                      margin-left: 22px;
                      .bold{
                        font-weight: bold;
                        
                      }
                      .upperCase{
                        text-transform: uppercase;
                      }
                      .suspectContent{
                        margin-top: 10px;
                        .MK{
                          margin-top: 8px;
                        }
                      }
                    
                      p{
                        color: black;
                        font-size: 12px;
                        line-height: 12px;
                      }
                    }
              
                    .timer {
                        position: absolute;
                        right: 8%;
                        font-size: 24px;
            
                        .smallTimer{
                          display: none;
                        }
                    
                    }
                  }
                  }

                  .choiceFeedBack{
                    margin-left: 22px;
                    margin-bottom: 60px;

                    .bold{
                      font-weight: bold;
                    }

                    .bottom{
                      margin-bottom: 60px;
                    }

                    .feedbackTitle {
                      margin-top: 10px;
                    }
                  }
                }
                

                .moreInfo{
                  position: fixed;
                  right: 50%;
                  width: 140px;
                  border-radius: 16px;
                  background-color: #E65100;
                  height: 44px;
                  top: 55%;
                  z-index: 99999999999;
                  transform: translate(50%,5px);
                  font-family: Barlow, sans-serif;
                }

                .moreInfo:hover{
                  background-color: #F4801B !important;
                  box-shadow: none;
                }
              }

            .case{
                border: 0.5px solid $primary;
                background: transparent linear-gradient(169deg, #707F8E 0%, #001935 100%) 0% 0% no-repeat padding-box;
                border-radius: 16px;
                width: 80%;
                margin: auto;
                margin-top: 20px;
                padding: 10px;
                .bold{
                    font-weight: bold;
                }
                p{
                    margin-left: 10px;
                    color: $white;
                }
            }
        }
    }
    .bottomContainer {
        justify-content: center;
        width: 100%;
        padding-left: unset;
        padding-right: unset;
        button:hover{
            background-color: #F4801B !important;
            box-shadow: none;
          }
        .button{
            margin-top: 5px;
        }
      }

      .outOfTime{
        display: none;
      }
}