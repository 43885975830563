.feedBack{
    width: 100%;
    height: 100%;
    .container{
        width: auto;
        height: 70%;
        margin-left: 22px;
        margin-right: 22px;

        .title{
            width: 100%;
            text-align: left;
            margin-top: 30px;
            h2{
                color: $white;
                text-shadow: unset;
            }
        }

        .textContent{
            margin-top: 16px;
            text-align: left;
            p{
                color: $white;
            }
        }
    }
    .bottomContainer {
        justify-content: center;
        button:hover{
            background-color: #F4801B !important;
            box-shadow: none;
          }
      }
}