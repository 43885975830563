.page {
  display: flex;
  flex-direction: column;
  height: fit-content;

  &-start {
    display: unset;
    height: 100%;
    
    @include media-breakpoint-up(lg){
      display: flex;
    }
  }

  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      height: -webkit-fill-available;
    }
  }

  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
      height: -webkit-fill-available;
    }
  }

  @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    height: -webkit-fill-available;
  }

  @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {
    height: -webkit-fill-available;
  }

  @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
    height: -webkit-fill-available;
  }

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

}

.topContainer {
  height: fit-content;
}

.topContainerStart {
  height: 80%;
}

.contentContainer {
  max-width: 768px;

  .textContainer {
    padding: 10px 10px 0px 10px;
    font-size: 14px;
    line-height: 21px;
    width: 90%;
    margin: auto auto 30px auto;
    color: $white;
    text-align: center;

    .heading {
      p{
        line-height: 2rem;
      }
    }
  }
}

.bottomContainer {
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 100;
  position: relative;
  &--center{
    justify-content: center;
  }
}

.bottomBuffer {
  margin-bottom: 50px;

  @media only screen and (max-height: 500px) {
    margin-bottom: 0px;
  }
}

.bottomBufferTAT {
  margin-bottom: 50px;
}

.skipContainer {
  height: 50px;
  bottom: 0px;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 110;
  display: flex;
}

.prev{
  width: 140px;
  height: auto;
  
  button{
    border: $white;
    border-width: 2px;
    border-style: solid;
    p{
      color: $white;
    }

    &:hover{
      border: #EE6900;
      border-width: 2px;
      border-style: solid;
      box-shadow: none;

      p{
        color:#EE6900;
      }
    }
  }

}

.introduction{
  .image {
    width: 70%;
    aspect-ratio: 16 / 9;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 30px;
    border-radius: 20px;
    opacity: 0.92;
    
    @media only screen and (max-width: 700px)  {
      width: 80%;
      height: 169px;
    }

    @media only screen and (max-height: 500px)  {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .image{
    overflow: hidden;
    img{
      width: 100%;
    }
  }
}

.topContent {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
}

.cover{
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(169deg, #707F8E 0%, #001935 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: color;

  @media only screen and (min-width: 600px)  {
    border-radius: 25px;
  }
}

.startPageImage {
  background: transparent url('../icons/Image-start.jpg') 50% 0% no-repeat padding-box;
  background-size: cover;
  width: 100%;
  max-width: 375px;
  max-height: 400px;

  @media only screen and (min-width: 600px)  {
    border-radius: 25px;
    margin-top: 100px;
  }

  @media not all and (min-resolution:.001dpcm)
  {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      height: 230px;
    }
  }

  @media not all and (min-resolution:.001dpcm){ 
    @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
      height: 230px;
    }
  }
  @media only screen 
  and (device-width: 390px) 
  and (device-height: 844px) 
  and (-webkit-device-pixel-ratio: 3) { 
    height: 230px;
  }
  @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
    height: 230px;
  }
  @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
    height: 230px;
  }

  @supports (-webkit-touch-callout: none) {
    height: 230px;
  }

  .content {
    margin-top: 20px;
    overflow-y: auto;
  }

}