.pagination {
    height: 6px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    .item{
        width: 6px;
        height: 100%;
        margin-right: 10px;
        background-color: $white;
        border-radius: 50%;
        &-selected{
            box-shadow: 0px 0px 5px 1px $primary;
        }
    }

    @media only screen and (max-height: 610px)  {
        display: none;
      }
}