
body {
  margin: 0;
  height: 100%;
  background-color: $default-background-color;
}

html {
  height: 100svh;
  font-size: 14px;

  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      height: -webkit-fill-available;
    }
  }

  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
      height: -webkit-fill-available;
    }
  }

  @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    height: -webkit-fill-available;
  }

  @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {
    height: -webkit-fill-available;
  }

  @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
    height: -webkit-fill-available;
  }

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

}

#root {
  height: 100%;
  width: 100%;
  background: $background-gradient;
}

.clock {
  position: absolute;
}

button {
  color: inherit;
  border: none;
  background: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.spinner {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.buttonCentered {
  margin: auto;
  width: 35%;
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -10%);
}



.nav {
  height: 50px;
  width: 100%;
  max-width: $max-content-width;
  display: flex;
  justify-content: center;
  margin-top: 22px;

  &-item {
    height: 44px;
    text-align: center;
    display: flex;
    align-items: center;
    z-index: 110;

    p {
      display: flex;
      color: $primary;
    }
  }

  &-button {
    position: absolute;
    left: 22px;
    height: 40px;
    width: 40px;
    background-color: #384C61;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 3px #32AEC44D;
    display: flex;
    justify-content: center;


  }

  .backButton {
    transform: rotate(180deg);
    align-items: center;
  }

  &-buttonIcon {
    height: 40px;
    width: 40px;
    background-color: #384C61;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 3px #32AEC44D;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: 10px;

    .backButton {
      transform: rotate(180deg);
    }
  }

  .clockRight {
    right: 70px;
    position: absolute;
  }

  .clockContainer {
    z-index: 9911109;
    width: 150px;
    height: 40px;
    background-color: #001935;
    box-shadow: 0px 0px 10px #32AEC44D;
    border: 0.5px solid $primary;
    border-radius: 7px;

    .profile {
      width: 32px;
      height: 32px;
      margin-left: 4px;
      margin-right: 8px;
      background-color: #384C61;
      box-shadow: 0px 3px 8px #00000014;
      border-radius: 6px;
      display: flex;
      justify-content: center;

      button {
        width: 100%;
        height: 100%;

        svg {
          margin-top: 50%;
          transform: translate(0, -50%);

          path {
            fill: $primary;
          }
        }
      }

      &-disable {
        button {
          width: 100%;
          height: 100%;

          svg {
            margin-top: 50%;
            transform: translate(0, -50%);

            path {
              fill: #717F8E;
            }
          }
        }
      }
    }
  }

  .profileIcon {
    width: 32px;
    height: 32px;
    margin-left: 4px;
    margin-right: 8px;
    background-color: #384C61;
    box-shadow: 0px 3px 8px #00000014;
    border-radius: 6px;
    margin-left: auto;
    margin-right: 10px;

    svg {
      margin-top: 50%;
      transform: translate(0, -50%);
    }
  }

  &-menu {
    height: 40px;
    width: 40px;
    position: absolute;
    right: 22px;
    background-color: #384C61;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 3px #32AEC44D;
    display: flex;
    justify-content: center;
    z-index: 9910110;

    button {
      height: 100%;
      width: 100%;

      svg {
        margin-top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  &-menuIcon {
    height: 40px;
    width: 40px;
    background-color: #384C61;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 3px #32AEC44D;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: 10px;

    div {
      height: 100%;
      width: 100%;
      margin: auto;

      svg {
        margin-top: 50%;
        transform: translate(0, -50%);
        fill: $primary;
      }
    }
  }
  .pageTitle {
    left: 84px;
    position: absolute;
    align-items: unset;
    flex-direction: column;
    margin-top: 4px;
    height: 40px;
    .title{
      color: $bleu;
      font-size: 1.3rem;
    }
    .pageContext{
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      p{
        color: $white;
      }
    }
  }
}

.body {
  width: 100%;
  height: 100%;
  position: fixed;
  max-width: $max-content-width;
  max-height: 100svh;
}



::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
  z-index: 500;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.dots {
  height: 100%;
  width: 100%;
  background: transparent url('../icons/BG-dots_home.svg') 0% 0% no-repeat padding-box;
  background-size: cover;
  opacity: 0.59;
  position: absolute;

  @media only screen and (min-width: 600px) {
    display: none;
  }
}

.lines {
  height: 100%;
  width: 90%;
  margin-left: 5%;
  background: transparent url('../icons/BG-lines.svg') 0% 0% no-repeat padding-box;
  background-size: cover;
  position: absolute;

  @media only screen and (min-width: 600px) {
    display: none;
  }
}

.orange {
  button:hover {
    background-color: #F4801B !important;
    box-shadow: none;
  }
}

.map {
  height: 100%;
  width: 100%;
  max-width: $max-content-width;
  background: transparent url('../icons/Map.svg') 0% 0% no-repeat padding-box;
  background-size: cover;
  opacity: 0.59;
  position: absolute;
  z-index: -10;
  // @media only screen and (min-width: 600px)  {
  //   display: none;
  // }
}

.elipsis {
  height: 300px;
  width: 290px;
  background: transparent url('../icons/Elipsis.svg') 0% 0% no-repeat padding-box;
  position: absolute;
  z-index: -10;
  position: absolute;
  left: 50%;
  top: 344px;
  transform: translate(-50%, -50%);

  @media only screen and (max-height: 715px) {
    top: 314px;
  }
}



.underline{
  button{
    p{
      color: #ED6A00;
      text-decoration: underline;
      text-decoration-thickness: 1.2px;
      max-width: 200px;
      margin: auto;
    }
    &:hover{
      box-shadow: none;
    }
  }
}