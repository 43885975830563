.Monitor{
    height: 100%;
    max-width: 768px;
    
    .screen{
        height: 95%;
        margin-left: 2.5%;
        margin-right: 2.5%;
        background-color: #1D1D1D;
        border-radius: 8px;
        padding: 10px 10px 0px 10px;
      
        .screenGlare{
          background: transparent url('../icons/glare.svg') 0% 0% no-repeat padding-box;
          width: 100%;
          height: 100%;
          position: absolute;
          background-size: contain;
          transform: translate(-10px, -10px);
          z-index: 9;
        }

        .title{
            position: absolute;
            background-color: #707F8E;
            width: 180px;
            height: 40px;
            display: flex;
            top: 48px;
            left: 50%;
            transform: translate(-50%,0px);
            border-radius: 7px;
            z-index: 989999;
            p{
                margin: auto;
                color: $white;
                font-size: 18px;
            }
            @media not all and (min-resolution:.001dpcm){ 
              @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
                p{
                  height: 12px;
                  line-height: 1.6rem;
                }
              }
            }
            @media only screen 
            and (device-width: 390px) 
            and (device-height: 844px) 
            and (-webkit-device-pixel-ratio: 3) { 
              p{
                height: 12px;
                line-height: 1.6rem;
              }
            }
            @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
              p{
                height: 12px;
                line-height: 1.6rem;
              }
            }
            @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
              p{
                height: 12px;
                line-height: 1.6rem;
              }
            }
      
            @supports (-webkit-touch-callout: none) {
              p{
                height: 12px;
                line-height: 1.6rem;
              }
            }
        }

        .display {
            margin: auto;
            background-color: #31312D;
            height: 100%;
            padding: 12px 10px 10px 10px;
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;
            z-index: 99999;
            max-height: 270px;

            @media only screen and (min-height: 400px)  {
              max-height: unset;
            }

            input{
                appearance: none;
                display: none;
            }

            input[type="radio"]:checked + .caseInfoMonitor{
                .contents{
                  background: rgba(#001935, 0.6) 0% 0% no-repeat padding-box;
                  .case-contents{
                    .textArea{
                      p{
                        color: $white;
                      }
                    }
                  }
                  .case-priorities{
                    .prioritieContainer{
                      .item{
                        .text{
                          color: $white;
                        }
                      }
                    }
                  }
                }
            }

            .selectable{
              position: absolute;
              z-index: 100000000000000000000000;
              width: 90%;
              margin-left: 5%;
              height: 86px;
              left: 0;
            }

            .caseInfoMonitor {
                width: 100%;
                height: 86px;
                transform: translate(0,0);
                z-index: 10;
                margin-bottom: 10px;
                .triangle{
                  height: 20px;
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-bottom: 10px solid $white;
                  position: absolute;
                  top: -29px;
                  left: 50%;
                  transform: translate(-50%,0);
                }
            
                .border{
                  width: 12px;
                  height: 86px;
                  border-radius: 6px  0px 0px 6px;
                  position: fixed;
                  top: 0;
                  left: 0;
                  
                  &-red{
                    background-color: red;
                  }
            
                  &-yellow{
                    background-color: #E4B905;
                  }
            
                  &-gray{
                    background-color: #C6CBD2;
                  }
                }
                .contents{
                  border-radius: 8px 6px 6px 8px;
                  background-color: $white;
                  width: 100%;
                  height: 86px;
                  display: flex;
                  .case-contents{
                    height: 76px;
                    width: 50%;
                    margin-top: 5px;
                    display: flex;
                    .textArea{
                      display: flex;
                      margin-left: 22px;
                      width: 100%;
                      .left{
                        width: 50%;
                      }

                      .bold{
                        font-weight: bold;
                        
                      }
                      .upperCase{
                        text-transform: uppercase;
                      }
                      .suspectContent{
                        margin-top: 10px;
                        .MK{
                          margin-top: 8px;
                        }
                      }
                    
                      p{
                        color: black;
                        font: normal normal 12px/12px Barlow, sans-serif;

                        &.bold {
                          font-weight: 700;
                        }
                      }
                    }
              
                    .timer {
                        position: absolute;
                        right: 8%;
                        font-size: 24px;
                    
                    }
                  }

                  .case-priorities{
                    height: 76px;
                    width: 50%;
                    margin-top: 5px;
                    display: flex;

                    .prioritieContainer{
                      width: 50%;
                      height: 35px;
                      position: absolute;
                      bottom: 21px;
                      display: flex;

                      

                      .item{
                        width: 48px;
                        height: 100%;
                        margin-right: 10px;
                        @media only screen and (max-width: 450px)  {
                          margin-right: 5px;
                        }
                        .text{
                          text-transform: uppercase;
                          color: black;
                          font: normal normal bold 14px/14px Barlow, sans-serif;
                          margin-bottom: 2px;
                        }

                        .box{
                          width: 100%;
                          height: 10px;
                          &-gray{
                            background-color: #C6CBD2;
                          }
                          &-yellow{
                            background-color: #E4B905;
                          }
                          &-green{
                            background-color: #61C754;
                          }
                        }
                      }
                    }
                    
                  }
                }
              }

          .bottomSpacing{
            margin-bottom: 100px;
          }

        }
    }
}

