.smallTimer{
    .content{
        p {
            text-shadow: unset;
        }

        &-red{
            p {
            color: red !important;
            }
          }
    
          &-yellow{
            p {
            color: #E4B905 !important;
            }
          }
    
          &-gray{
            p {
            color: #C6CBD2 !important;
            }
          }
    }
}

.outOfTime{
  position: fixed;
  width: 100%;
  height: 100% ;
  top: 0;
  left: 0;
  background: rgba(#001935, 0.6);
  z-index: 10;
  border-radius: 6px;

  svg{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-10deg);
  }
}