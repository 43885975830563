.Detail{
  width: 100%;
  height: 100%;
  overflow: auto;
  .dotsOverlay{
    width: 100%;
    height: 558px;
    margin-top: -72px;
    box-shadow: 0px 0px 16px #00000099;
    background: transparent linear-gradient(157deg, #707F8E 0%, #001935 80%) 0% 0% no-repeat padding-box;
    position: absolute;
    @media only screen and (min-width: 600px)  {
      height: 698px;
    }
    .dots{
      width: 100%;
      height: 558px;
      position: absolute;
      @media only screen and (min-width: 600px)  {
        height: 698px;
      }
    }
    .title{
      width: fit-content;
      background-color: #ED6A00;
      border-radius: 6px;
      padding: 5px;
      width: fit-content;
      max-width: 70%;
      margin: auto;
      margin-top: 70px;
      position: relative;
      margin-bottom: -20px;
      p{
        font-size: 14px;
        text-align: center;
        color: $white;
        height: fit-content;
        padding: 0;
      }
    }

    .journalImage{
      aspect-ratio: 16 / 9;
      object-fit: cover;
      width: 92%;
      border-radius: 16px;
      margin-left: 4%;
    }

    .suspectData{
      width: auto;
      height: 230px;
      margin: 0px 22px 0px 22px;
      display: flex;

      div{
        width: 50%;
        height: 100%;
        .item{
          width: 100%;
          height: fit-content;
          margin-top: 8px;
        }
      }

      .bold{
        font-weight: bold;
        margin-bottom: 2px;
        @media not all and (min-resolution:.001dpcm)
          { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
             margin-bottom: unset;
          }
        }
        @media not all and (min-resolution:.001dpcm){ 
          @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
            margin-bottom: unset;
          }
        }
        @media only screen 
          and (device-width: 390px) 
          and (device-height: 844px) 
          and (-webkit-device-pixel-ratio: 3) { 
            margin-bottom: unset;
          }
          @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
            margin-bottom: unset;
          }
          @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
            margin-bottom: unset;
          }
      
          @supports (-webkit-touch-callout: none) {
            margin-bottom: unset;
          }
    
      }

      p{
        font-size: 14px;
        line-height: 16px;
        color: $white;
        height: fit-content;
        padding: 0;
      }
    }
  }
  
  .paperBackground{
    width: 100%;
    height: fit-content;
    margin-top: 488px;
    background-color: $white;

    @media only screen and (min-width: 600px)  {
      margin-top: 618px;
    }
    .paper{
      width: 100%;
      height: 100%;
      background-image: url('../icons/wall.svg');
      background-repeat:repeat;
      padding-top: 22px;
      .context{
        width: auto;
        margin-left: 22px;
        margin-right: 22px;
      }

      .caseStatus{
        width: auto;
        margin: 20px 22px 44px 22px;
      }
    }
  }
  .paperButton{
    background-color: white;
    background-image: url('../icons/wall.svg');
    position: sticky;
    bottom: 0;
    height: 78px;
    padding-top: 28px;
    .bottomContainer{
      .prev{
        button{
          border: #ED6A00;
          border-width: 2px;
          border-style: solid;
          p{
            color: #ED6A00;
          }
        }
      }
    }
    
  }
  .button button .text p {
    display: block;
}
}

