//colors
$black: #000000;
$bleu: #46f1ff;
$shadow_blue: #32AEC4E6;
$lightGrey: #CCCCCC;
$dolphinGrey: #E1E1E3;
$lighterGrey: #F4F4F6;
$white: #FFFFFF;


$primary: $bleu;
$secondary: $white;
$tertiary: $lightGrey;
$quaternary: $lighterGrey;

$primary-background: $white;
$secondary-background: $lighterGrey;
$shadow: $shadow_blue;

// Font size
$h1-font-size: 2.9rem;
$h2-font-size: 2.1rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.5rem;

$xs-font-size: px(14);
$sm-font-size: px(16);
$base-font-size: px(20);
$lg-font-size: px(22);

$grid-breakpoints: (
	xs: 0,
	sm: 320px,
	md: 375px,
	lg: 425px,
	xl: 1200px,
	xxl: 1024px,
) !default;

$container-max-widths: (
    sm: 320px,
    md: 375px,
    lg: 425px,
    xl: 768px,
    xxl: 1024px
);

//colors
$default-background-color: #001935;

$background-gradient: transparent linear-gradient(169deg, #707F8E 0%, $default-background-color 75%) 0% 0% no-repeat padding-box;

//sizes
$max-content-width: 768px;