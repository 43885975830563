.dynamicComponent{
    height: 100svh;
    max-width: 768px;
    width: auto;
    margin: auto;
    @media not all and (min-resolution:.001dpcm)
    { 
        @supports (-webkit-appearance:none) and (stroke-color:transparent) {
            height: -webkit-fill-available;
        }
    }
    @media not all and (min-resolution:.001dpcm){ 
        @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
            height: -webkit-fill-available;
        }
    }

    @media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3) { 
        height: -webkit-fill-available;
    }
    @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
        height: -webkit-fill-available;
    }
    @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
        height: -webkit-fill-available;
    }

    @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
    }

}