//CSS //orientation


.orientation-message-container {
  display: none;
  position: absolute;
  inset: 0;
  z-index: 999999;
  background-color: $default-background-color;
  background: $background-gradient;

  .background {
    @include map-background;
    height: 100%;
    width: 100%;
    max-width: $max-content-width;
    position: relative;
    margin: 0 auto;
    z-index: -10;
  }

  .landscape-orientation-message,
  //'turn to landscape' message
  .portrait-orientation-message {
    //'turn to portrait' message
    display: none;
  }

  .icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .rotate-icon {
      height: 150px;
      width: 150px;
      background: transparent url('../icons/Screen_rotation.svg') 0% 0% no-repeat padding-box;
      background-size: cover;

      &-left {
        transform: rotate(0deg);
        -webkit-animation: spin-left 3s linear infinite;
        -moz-animation: spin-left 3s linear infinite;
        animation: spin-left 3s linear infinite;
        animation-iteration-count: infinite;
      }

      &-right {
        transform: rotate(-90deg);
        -webkit-animation: spin-right 3s linear infinite;
        -moz-animation: spin-right 3s linear infinite;
        animation: spin-right 3s linear infinite;
        animation-iteration-count: infinite;
      }
    }

    h3 {
      text-align: center;
      margin-top: 19px;
    }
  }

}

body.portrait {
  @media screen and (orientation:landscape) {
    overflow: hidden;

    .orientation-message-container {
      display: block;

      .portrait-orientation-message {
        display: block;
      }
    }
  }
}

body.landscape {
  @media screen and (orientation:portrait) {
    overflow: hidden;

    .orientation-message-container {
      display: block;

      .landscape-orientation-message {
        display: block;
      }
    }
  }
}

@-moz-keyframes spin-left {
  80% {
    -moz-transform: rotate(-90deg);
  }

  100% {
    -moz-transform: rotate(-90deg);
  }
}

@-webkit-keyframes spin-left {
  80% {
    -webkit-transform: rotate(-90deg);
  }

  100% {
    -webkit-transform: rotate(-90deg);
  }
}

@keyframes spin-left {
  80% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}


@-moz-keyframes spin-right {
  80% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(0deg);
  }
}

@-webkit-keyframes spin-right {
  80% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes spin-right {
  80% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}