.decision{
  justify-content: center;




  .bottomContent {
    position: absolute;
    display: block;
    bottom: 20px;
    width: 100%;
    max-width: 768px;
  }

  .selectQuestion{
    text-align: center;
    color: $white;
    font-size: 14px;
    line-height: 0px;
  }

  .pageContent{
    width: 100%;
    height: fit-content;
    overflow: auto;
    @media only screen and (max-height: 710px)  {
      max-height: 480px;
    }
    @media only screen and (max-height: 600px)  {
      max-height: 380px;
    }
    @media only screen and (max-height: 510px)  {
      max-height: 250px;
    }
  }

  
  .chatContainer{
    width: 100%;
    bottom: 40%;
    z-index: 10;
    overflow-y: auto;
    margin-bottom: 22px;
    
    @media only screen and (max-height: 560px)  {
      margin-top: 10px;
    }
    
    input{
      -webkit-appearance: none;
      appearance: none;
    }
    
    input:checked + .right{
      .userMessage{
        border: 1px solid var(---ed6a00);
        background: #001935 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 6px #00000029;
        border: 1px solid #ED6A00;
        p{
          color: $white;
        }
      }
    }

    .right{
      justify-content: right;

      .userMessage{
        background-color: $white;
        box-shadow: 0px 5px 6px #00000029;
        max-width: 80%;
        height: fit-content;
        width: fit-content;
        border-radius: 16px 16px 0px 16px;
        margin-bottom: 8px;
        margin-right: 8%;
        p{
          margin: 12px 12px 12px 12px;
          color: black;
        }
        @media only screen and (max-height: 710px)  {
          margin-right: unset;
          margin: auto;
        }
      }
    }

    .left{
      justify-content: left;
      .botMessage{
        background-color: #556578;
        box-shadow: 0px 5px 6px #00000029;
        max-width: 80%;
        height: fit-content;
        width: fit-content;
        margin-left: 8%;
        border-radius: 25px 25px 25px 0px;
        margin-bottom: 8px;
        p{
          margin: 8px 12px 8px 12px;
          color: $white;
        }
      }
    }
    .message{
      width: 100%;
      height: fit-content;
      display: flex;
    }
    #bottomMessage{
      height: 50px;
      width: 100%;
      overflow-anchor: auto;
    }
    .buffer{
      width: 100%;
      height: 100px;
    }
  }
  .swiper{
    bottom: 0px;
    height: 150px;
    width: 100%;
    // transform: translate(-33.3333333333%,0px);
    .swiper-slide {
      margin: auto;
      text-align: center;
      height: 110px;
      div{
        margin: auto;
        height: 100px;
        width: 85%;
        background-color: $white;
        border-radius: 25px;
        p{
          color: black;
        }
      }
      

      button:disabled{
        opacity: .5;
      }

      .send{
        width: 160px;
        height: 44px;
        margin-top: 60px;
        border-radius: 25px;
        background-color: #E65100;
        p{
          font-weight: bold;
        }
      }
    }
    .swiper-button{
      &-prev{
        --arrow: transparent url('data:image/svg+xml,\<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="white"><path d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"/></svg>')  50% 0% no-repeat padding-box;

        width: 44px;
        height: 44px;
        background-color: #556578;
        border-radius: 50%;
        &::after{
          background:var(--arrow);  
          fill: $white;
          content: '';
          width: 16px;
          height: 16px;
          transform: rotate(180deg);
        }
      }

      &-next{
        --arrow: transparent url('data:image/svg+xml,\<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="white"><path d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"/></svg>')  50% 0% no-repeat padding-box;

        width: 44px;
        height: 44px;
        background-color: #556578;
        border-radius: 50%;
        // right: 33.3333333333%;
        &::after{
          background:var(--arrow);  
          fill: $white;
          content: '';
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .caseInfoMonitor {
    width: auto;
    height: 86px;
    transform: translate(0,0);
    z-index: 10;
    margin: 5px 22px 10px 22px;
    .triangle{
      height: 20px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $white;
      position: absolute;
      top: -29px;
      left: 50%;
      transform: translate(-50%,0);
    }

    .border{
      width: 12px;
      height: 86px;
      border-radius: 6px  0px 0px 6px;
      position: fixed;
      top: 0;
      left: 0;
      
      &-red{
        background-color: red;
      }

      &-yellow{
        background-color: #E4B905;
      }

      &-gray{
        background-color: #C6CBD2;
      }
    }
    .contents{
      border-radius: 8px 6px 6px 8px;
      background-color: $white;
      width: 100%;
      height: 86px;
      display: flex;
      .case-contents{
        height: 76px;
        width: 100%;
        margin-top: 5px;
        display: flex;
        .textArea{
          display: flex;
          margin-left: 22px;
          .bold{
            font-weight: bold;
            
          }
          .upperCase{
            text-transform: uppercase;
          }
          .suspectContent{
            margin-top: 10px;
            .MK{
              margin-top: 8px;
            }
          }
        
          p{
            color: black;
            font-size: 12px;
            line-height: 12px;
          }
        }
  
        .timer {
            position: absolute;
            right: 8%;
            font-size: 24px;

            .smallTimer{
              display: none;
            }
        
        }
      }

      .case-priorities{
        height: 76px;
        width: 50%;
        margin-top: 5px;
        display: flex;

        .prioritieContainer{
          width: 100%;
          height: 35px;
          position: absolute;
          bottom: 21px;
          display: flex;

          .item{
            width: 48px;
            height: 100%;
            margin-right: 10px;

            .text{
              text-transform: uppercase;
              color: black;
              font: normal normal bold 14px/14px Barlow, sans-serif;
              margin-bottom: 2px;
            }

            .box{
              width: 100%;
              height: 10px;
              &-gray{
                background-color: #C6CBD2;
              }
              &-yellow{
                background-color: #E4B905;
              }
              &-green{
                background-color: #61C754;
              }
            }
          }
        }
        
      }
    }
  }

  .title{
    width: 100%;
    h3{
      text-align: center;
      font: normal normal normal 20px/24px Barlow, sans-serif;
      letter-spacing: 0px;
      color: $primary;
      text-shadow: 0px 0px 10px $shadow;
    }
  }

  .description{
    width: auto;
    margin: 0px 22px 19px 22px;
    p{
      text-align: center;
      font: normal normal normal 14px/21px Barlow, sans-serif;
      letter-spacing: 0px;
      color: #FFFFFF;
    }
  }

  .decisionButtons{
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: center;
    .buttonContainer{
      width: 375px;
      height: 44px;
      display: flex;
      justify-content: center;
      .item{
        width:140px;
        height: 100%;
        margin-top: 20px;
        .primary{
          button:hover{
            background-color: #F4801B !important;
            box-shadow: none;
          }
        }
      }

      .buffer{
        width: 24px;
        height: 100%;
      }
    }
  }

}