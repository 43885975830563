.timer_button {
    font-size: 16px;
    padding: 15px 40px;
    margin: 10px auto 30px;
    line-height: 24px;
    display: block;
    background-color: rgb(77, 77, 77);
    color: lightgray;
    border: none;
    cursor: pointer;
    outline: 0px;
}

.timer_container {
    width: fit-content;
    height: 20px;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    
    .content{
        display: flex;
        
        padding: 0;
        width: 100%;
        height: 100%;
        p {
            color: $primary;
            font-weight: bold;
            text-shadow: 0px 0px 10px $shadow;
            font-size: 20px;
            height: 20px;
            line-height: 20px;
            padding: 0;

            @media not all and (min-resolution:.001dpcm)
            { @supports (-webkit-appearance:none) and (stroke-color:transparent) {

                p {
                    margin-top: 6px;
                }
            }}
            @media not all and (min-resolution:.001dpcm){ 
                @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
                    p {
                        margin-top: 6px;
                    }
                }
              }
              @media only screen 
              and (device-width: 390px) 
              and (device-height: 844px) 
              and (-webkit-device-pixel-ratio: 3) { 
                p {
                    margin-top: 6px;
                }
              }
              @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
                p {
                    margin-top: 6px;
                }
              }
              @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
                p {
                    margin-top: 6px;
                }
              }
          
              @supports (-webkit-touch-callout: none) {
                p {
                    margin-top: 6px;
                }
              }
          
        }
    
        .small{
            font-size: small;
            line-height: 12px;
            height: 12px;
            padding: 0;

            
        }

        @media not all and (min-resolution:.001dpcm)
            { @supports (-webkit-appearance:none) and (stroke-color:transparent) {

                p:not(.small) {
                    height: .9rem;
                }

                .small{
                    height: 1.2rem;

                }
        }}
        @media not all and (min-resolution:.001dpcm){ 
            @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
                p:not(.small) {
                    height: .9rem;
                }

                .small{
                    height: 1.2rem;

                }
            }
          }
          
          @media only screen 
          and (device-width: 390px) 
          and (device-height: 844px) 
          and (-webkit-device-pixel-ratio: 3) { 
            p:not(.small) {
                height: .9rem;
            }

            .small{
                height: 1.2rem;

            }
          }
          @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
            p:not(.small) {
                height: .9rem;
            }

            .small{
                height: 1.2rem;

            }
          }
          @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
            p:not(.small) {
                height: .9rem;
            }

            .small{
                height: 1.2rem;

            }
          }
      
          @supports (-webkit-touch-callout: none) {
            p:not(.small) {
                height: .9rem;
            }

            .small{
                height: 1.2rem;

            }
          }
    }
}

.paused{
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
    background: rgba(#001935, 0.6);
    border-radius: 7px;
    position: absolute;
    
    strong{
        margin: auto;
        transform: matrix(0.98, -0.17, 0.17, 0.98, 0, 0);
        text-transform: uppercase;
        text-align: right;
        font: normal normal bold 20px/24px Barlow, sans-serif;
        letter-spacing: 0px;
        color: #FFFFFF !important;
        text-shadow: 0px 0px 10px $shadow;
        z-index: 200;

        @media not all and (min-resolution:.001dpcm)
          { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
             margin-top: 15px;
          }
        }
        @media not all and (min-resolution:.001dpcm){ 
            @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
                margin-top: 15px;
            }
          }
          @media only screen 
          and (device-width: 390px) 
          and (device-height: 844px) 
          and (-webkit-device-pixel-ratio: 3) { 
            margin-top: 15px;
          }
          @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
            margin-top: 15px;
          }
          @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
            margin-top: 15px;
          }
      
          @supports (-webkit-touch-callout: none) {
            margin-top: 15px;
          }
      
    }
}
.time_indicator {
    color: lightgreen;
    font-size: 20px;
}

.timer_body{
    top: 6.5%;
    right: 3%;
}

.timer_svg{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: rotateY(-180deg) rotateZ(-90deg);
    overflow: visible;
}
