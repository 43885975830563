.chat{
  justify-content: center;

  .bottomContent {
    position: absolute;
    display: block;
    bottom: 20px;
    width: 100%;
    max-width: 768px;
  }

  .selectQuestion{
    text-align: center;
    color: $white;
    font-size: 14px;
    line-height: 0px;
    margin-bottom: -25px;
    @media only screen and (max-height: 715px)  {
      margin-bottom: -50px;
    }
  }

  .profileIconContainer{
    height: 255px;
    width: 100%;
    margin-bottom: 25px;
    .icon{
      height: 235px;
      width: 235px;

      border: $primary;
      border-radius: 50%;
      border-width: 2px;
      border-style: solid;

      margin: auto;
      box-shadow: 0px 0px 10px $shadow;

      overflow: hidden;

      @media only screen and (max-height: 715px)  {
        height: 135px;
        width: 135px;
      }

      .image{
        width: 165px;
        height: 263px;
        margin-left: 50%;
        transform: translate(-50%, 0);
      }

      .iconContainer{
        width: 51px;
        height: 51px;
        position: absolute;
        border-radius: 50%;
        background-color: $white;
        left: 50%;
        transform: translate(-50%, -51px);
        
        .chat-shn{
          position: absolute;
          left: 50%;
          transform: translate(-50%, 13px);
        }
        .chat-rvk{
          position: absolute;
          left: 50%;
          transform: translate(-50%, 10px);
        }
        .chat-halt{
          position: absolute;
          left: 50%;
          transform: translate(-50%, 14px);
        }
        .chat-pol{
          position: absolute;
          left: 50%;
          transform: translate(-50%, 5px);
        }
      }
    }

    &--bottomLeft{
      width: 90%;
      margin-top: 25%;
      height: fit-content;
      margin-left: 8%;
      display: flex;
      position: sticky;
      
      @media only screen and (max-height: 715px)  {
        display: none;
      }
      .icon{
        height: 90px;
        width: 90px; 
        border: $primary;
        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
        margin-top: auto;
        margin-bottom: 42px;
        box-shadow: 0px 0px 10px $shadow;

        overflow: hidden;
        
        .image{
          width: 68.36px;
          height: auto;
          margin-left: 50%;
          transform: translate(-50%, 0);
        }

        .iconContainer{
          width: 51px;
          height: 51px;
          position: absolute;
          border-radius: 50%;
          background-color: $white;
          left: 46px;
          transform: translate(-50%, -61px);

          .chat-shn{
            position: absolute;
            left: 50%;
            transform: translate(-50%, 13px);
          }
          .chat-rvk{
            position: absolute;
            left: 50%;
            transform: translate(-50%, 10px);
          }
          .chat-halt{
            position: absolute;
            left: 50%;
            transform: translate(-50%, 14px);
          }
          .chat-pol{
            position: absolute;
            left: 50%;
            transform: translate(-50%, 5px);
          }

        }
      }
    }
  }

  .questionGrouping
  {
   
    @media only screen and (max-height: 715px)  {
      position: absolute;
      bottom: -30px;
    }
    
  }

  .QuestionsContainer{
    width: 100%;
    display: grid;
  }

  
  .chatContainer{
    width: 100%;
    height: 50%;
    max-width: 768px;
    min-height: 60%;
    bottom: 40%;
    position: absolute;
    z-index: 10;
    overflow-y: auto;

    .right{
      justify-content: right;

      .userMessage{
        background-color: $white;
        box-shadow: 0px 5px 6px #00000029;
        max-width: 80%;
        height: fit-content;
        width: fit-content;
        border-radius: 25px 25px 0px 25px;
        margin-bottom: 8px;
        margin-right: 8%;
        p{
          margin: 12px 12px 12px 12px;
          color: black;
        }
      }
    }

    .left{
      justify-content: left;
      .botMessage{
        background-color: #556578;
        box-shadow: 0px 5px 6px #00000029;
        max-width: 80%;
        height: fit-content;
        width: fit-content;
        margin-left: 8%;
        border-radius: 25px 25px 25px 0px;
        margin-bottom: 8px;
        p{
          margin: 8px 12px 8px 12px;
          color: $white;
        }
      }
    }
    .message{
      width: 100%;
      height: fit-content;
      display: flex;
    }
    #bottomMessage{
      height: 50px;
      width: 100%;
      overflow-anchor: auto;
    }
    .buffer{
      width: 100%;
      height: 100px;
    }
  }
  .swiper{
    bottom: 0px;
    height: 200px;
    width: 100%;
    // transform: translate(-33.3333333333%,0px);
    .swiper-slide {
      margin: auto;
      text-align: center;
      height: fit-content;
      div{
        margin: auto;
        height: 80%;
        width: 85%;
        background-color: $white;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
    
        p{
          color: black;
          margin: auto;
          width: 80%;
        }
      }
      

      button:disabled{
        opacity: .5;
      }

      .send{
        width: 160px;
        height: 44px;
        border-radius: 25px;
        background-color: #E65100; 
        position: absolute;
        left: 50%;
        bottom: -35px;
        transform: translate(-50%);
        p{
          font-weight: bold;
        }
      }
    }
    .swiper-button{
      &-prev{
        --arrow: transparent url('data:image/svg+xml,\<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="white"><path d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"/></svg>')  50% 0% no-repeat padding-box;

        width: 44px;
        height: 44px;
        background-color: #556578;
        border-radius: 50%;
        &::after{
          background:var(--arrow);  
          fill: $white;
          content: '';
          width: 16px;
          height: 16px;
          transform: rotate(180deg);
        }
      }

      &-next{
        --arrow: transparent url('data:image/svg+xml,\<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="white"><path d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"/></svg>')  50% 0% no-repeat padding-box;

        width: 44px;
        height: 44px;
        background-color: #556578;
        border-radius: 50%;
        // right: 33.3333333333%;
        &::after{
          background:var(--arrow);  
          fill: $white;
          content: '';
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}