.journal {
  width: 100%;
  height: 100%;

  .container {
    width: 100%;
    height: 100%;

    .title {
      width: 100%;
      text-align: center;
      margin-top: 30px;
    }

    .display {
      width: 100%;
      height: 75%;
      margin-top: 30px;
      overflow-y: auto;

      .item {
        display: block;
        margin: 0px 22px 12px 22px;
        height: 170px;

        .button {
          height: 0px;
        }

        .journalContent {
          width: 100%;
          height: 100%;
          border-radius: 16px;
          overflow: hidden;
          position: relative;

          .journalImage {
            position: absolute;
            width: -webkit-fill-available;
            left: 0px;
          }

          .overlay {
            height: -webkit-fill-available;
            background: transparent linear-gradient(180deg, #1C324B00 0%, #001935F2 68%, #001935F7 100%) 0% 0% no-repeat padding-box;
          }

          .journalTitle {
            height: 115px;
            width: 100%;
            position: relative;

            .alignBottom {
              position: absolute;
              bottom: 0;
              display: block;
              margin: 20% 12px 0 12px;
              height: 50%;

              p {
                font-size: 18px;
                color: $white;
                text-align: left;
              }
            }

          }

          .text {
            height: 50px;
            display: flex;
            margin: 0 12px 0 12px;
            justify-content: center;
            flex-direction: column;
            position: relative;
            width: auto;

            .bold {
              font-weight: bold;
            }

            p {
              font-size: 12px;
              line-height: 12px;
              color: $white;
              font-weight: normal;
              text-align: left;
            }
          }
        }
      }
    }
  }
}