.roundTable{
  text-align: center;
  max-width: 768px;

  
  h2 {
    margin-top: 35px;
    margin-bottom: 10px;
    
    @media only screen and (max-height: 715px)  {
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }

  .subHeading {
    width: 90%;
    margin: auto;
    font-size: 14px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: $white;
  }

  .table-content {
    width: 100%;
    height: 60%;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    @media only screen and (max-height: 715px)  {
      height: 58%;
      margin-bottom: 0px;
    }
  }

  .radioButtons{
    height: 100%;
    position: absolute;
    transform: translate(-190px, -190px);
    label{
      input{
        width: 65px;
        height: 65px;
        -webkit-appearance: none;
        /* Remove most all native input styles */
        appearance: none;
        background-color: $white;
        margin: 0;
        font: inherit;
        color: black;
        border-radius: 50%;
        transform: translateY(-0.075em);

        display: grid;
        place-content: center;
      }
      input::before{
        content: "";
        width: 81px;
        height: 81px;
        transition: 120ms transform ease-in-out;
        /* Windows High Contrast Mode */
        transform: scale(0);
        border-radius: 100%;
        border: 2px solid $primary;
        background-color: transparent;
      }

      input:checked::before {
        transform: scale(1);
      }
      

      &:hover {
        input::before{
          transform: scale(1);
        }
      }
    }
  }

  .buttonContainer{
    height: 64px;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    .chatInfo{
      display: flex;
      height: 64px;
      width: 70%;
      position: absolute;
      left: 22px;
      text-align: center;
      align-items: center;
      justify-content: center;
      color:$primary;
      text-align: center;

      @media not all and (min-resolution:.001dpcm){ 
        @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
          p{
            height: 12px;
          }
        }
      }
      @media only screen 
      and (device-width: 390px) 
      and (device-height: 844px) 
      and (-webkit-device-pixel-ratio: 3) { 
        p{
          height: 12px;
        }
      }
      @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {  
        p{
          height: 12px;
        }
      }
      @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {  
        p{
          height: 12px;
        }
      }

      @supports (-webkit-touch-callout: none) {
        p{
          height: 12px;
        }
      }
    }

    .chatButton{
      width: 30%;
      height: 64px;
      position: absolute;
      right: 0px;
      Button{
        border-radius: 50%;
        svg{
          position: relative;
          top:  2px;
        }
      }
    }
  }
}

.arrow{
  fill: $primary;
  margin-top: 15%;
  g{
    path{
      stroke: $primary;
    }
  }

  &-back{
    margin-top: unset;
  }

  &-backIcon{
    margin-top: 2px;
  }
}

.pol {
  position: absolute;
  top: 244px;
  left: 46px;

  @media only screen and (max-height: 715px)  {
    top: 214px;
  }
}
.pol-logo{
  transform: translate(55%, 25%);
}
.hlt {
  position: absolute;
  top: 466px;
  left: 108px;
  @media only screen and (max-height: 715px)  {
    top: 436px;
  }
}

.hlt-logo{
  transform: translate(80%, 90%);
}

.shn {
  position: absolute;
  top: 387px;
  left: 279px;
  @media only screen and (max-height: 715px)  {
    top: 357px;
  }
}

.shn-logo{
  transform: translate(42%, 70%);
}

.rvk {
  position: absolute;
  top: 292px;
  left: 127px;
  @media only screen and (max-height: 715px)  {
    top: 262px;
  }
}

.rvk-logo{
  transform: translate(46%, 55%);
}

.recl {
  position: absolute;
  top:272px;
  left: 273px;
  @media only screen and (max-height: 715px)  {
    top: 242px;
  }
}

.recl-logo{
  transform: translate(400%, 55%);
}


